<template>
  <div class="table-responsive">
    <table class="table table-center table-padding mb-0">
      <tbody>
        <tr>
          <td class="h6 border-0">Plan</td>
          <td class="text-center font-weight-bold border-0">{{plan.name}}</td>
        </tr>
        <tr>
          <td class="h6">Subtotal</td>
          <td class="text-center font-weight-bold">
            $ {{plan.price.toFixed(2)}}
          </td>
        </tr>
        <tr>
          <td class="h6">Tax</td>
          <td class="text-center font-weight-bold">$ {{(0).toFixed(2)}}</td>
        </tr>
        <tr class="bg-light">
          <td class="h5 font-weight-bold">Total</td>
          <td class="text-center text-primary h4 font-weight-bold">
            $ {{plan.price.toFixed(2)}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    props: {
      plan: {
        type: Object,
        required: true,
      }
    }
  }
</script>